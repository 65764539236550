import { useEffect, useState } from "react";
import st from "./movieItem.module.css";
import { Link, useParams } from "react-router-dom";
import { useTheme } from "../../../context/theme";
import {api} from '../../../services'
export default function SubCategoryItem({ movie = {} }) {
  const language = useParams();
  const [dark] = useTheme();
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
      <Link
          to={`/${language.lang || "ru"}/sub-category/${movie.id}`}
          className={st.container}
      >
        <div
            className={`${st.imgBox} ${movie && imageLoaded ? "" : st.animate}`}
        >
          <img
              onLoad={() => {
                setImageLoaded(true);
              }}
              src={`${api}/${movie.image}`}
              style={{ visibility: movie && imageLoaded ? "" : "hidden" }}
              alt=""
          />
        </div>
        <div
            className={`${st.title} ${movie ? "" : st.animate}`}
            style={{ color: dark ? " " : "black" }}
        >
          {movie.name}
        </div>
      </Link>
  );
}
